exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-categories-tsx": () => import("./../../../src/pages/categories.tsx" /* webpackChunkName: "component---src-pages-categories-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-newsstand-tsx": () => import("./../../../src/pages/newsstand.tsx" /* webpackChunkName: "component---src-pages-newsstand-tsx" */),
  "component---src-pages-pressshuffle-tsx": () => import("./../../../src/pages/pressshuffle.tsx" /* webpackChunkName: "component---src-pages-pressshuffle-tsx" */),
  "component---src-pages-redirect-tsx": () => import("./../../../src/pages/redirect.tsx" /* webpackChunkName: "component---src-pages-redirect-tsx" */),
  "component---src-pages-sources-tsx": () => import("./../../../src/pages/sources.tsx" /* webpackChunkName: "component---src-pages-sources-tsx" */),
  "component---src-templates-mdx-page-template-js-content-file-path-src-mdx-pages-about-mdx": () => import("./../../../src/templates/MDXPageTemplate.js?__contentFilePath=/codebuild/output/src1051505009/src/schroeder-web-shuffle/src/mdx-pages/about.mdx" /* webpackChunkName: "component---src-templates-mdx-page-template-js-content-file-path-src-mdx-pages-about-mdx" */),
  "component---src-templates-mdx-page-template-js-content-file-path-src-mdx-pages-apps-mdx": () => import("./../../../src/templates/MDXPageTemplate.js?__contentFilePath=/codebuild/output/src1051505009/src/schroeder-web-shuffle/src/mdx-pages/apps.mdx" /* webpackChunkName: "component---src-templates-mdx-page-template-js-content-file-path-src-mdx-pages-apps-mdx" */),
  "component---src-templates-mdx-page-template-js-content-file-path-src-mdx-pages-contact-mdx": () => import("./../../../src/templates/MDXPageTemplate.js?__contentFilePath=/codebuild/output/src1051505009/src/schroeder-web-shuffle/src/mdx-pages/contact.mdx" /* webpackChunkName: "component---src-templates-mdx-page-template-js-content-file-path-src-mdx-pages-contact-mdx" */),
  "component---src-templates-mdx-page-template-js-content-file-path-src-mdx-pages-source-code-mdx": () => import("./../../../src/templates/MDXPageTemplate.js?__contentFilePath=/codebuild/output/src1051505009/src/schroeder-web-shuffle/src/mdx-pages/source-code.mdx" /* webpackChunkName: "component---src-templates-mdx-page-template-js-content-file-path-src-mdx-pages-source-code-mdx" */),
  "component---src-templates-mdx-page-template-js-content-file-path-src-mdx-pages-terms-privacy-mdx": () => import("./../../../src/templates/MDXPageTemplate.js?__contentFilePath=/codebuild/output/src1051505009/src/schroeder-web-shuffle/src/mdx-pages/terms-privacy.mdx" /* webpackChunkName: "component---src-templates-mdx-page-template-js-content-file-path-src-mdx-pages-terms-privacy-mdx" */),
  "component---src-templates-source-template-js": () => import("./../../../src/templates/SourceTemplate.js" /* webpackChunkName: "component---src-templates-source-template-js" */)
}

